export const status = [
  {
    value: 0,
    label: '未使用'
  },
  {
    value: 1,
    label: '已使用'
  },
  {
    value: 2,
    label: '已失效'
  }
]

export const expiration = [{
    value: '',
    label: '全部'
  },
  {
    value: 1,
    label: '管理员创建'
  },
  {
    value: 2,
    label: '用户申请'
  }
]

export const emailOption= [
    {
        value: 1,
        label: '未发送'
    },
    {
      value: 2,
      label: '已发送'
    },
    {
        value: 3,
        label: '发送中'
    }
]
