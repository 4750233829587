<template>
  <div v-if="hasResource('activation_code_management')">
    <div class="search-form">
      <el-button
        class="black-btn btn-l"
        v-if="hasResource('activation_code_create')"
        @click="handleAddNew"
      >新增</el-button>
      <activation-search />
    </div>
    <activation-list ref="activationList" @handleItem="handleItem" />
    <send-email-dialog
        ref="sendEmailPop"
        @updateList="updateList"
        type="inception"
    />
  </div>
</template>

<script>
import activationSearch from "./components/activation-search";
import activationList from "./components/activation-list";
import sendEmailDialog from "@/pages/_common/send_email_dialog/send_email_dialog"
export default {
  name: "activationManagement",
  components: { activationSearch, activationList, sendEmailDialog },
  data() {
    return {};
  },
  methods: {
    handleAddNew() {
        this.$router.push('/inception-key-add');
    },

    handleItem(val) {
    this.$nextTick(() => {
        this.$refs.sendEmailPop.show(val);
      });
    },
    updateList() {
      this.$nextTick(() => {
        this.$refs.activationList.init();
      });
    },
  },
  mounted() {},
};
</script>
