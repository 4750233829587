<template>
  <div class="dis-fix">
    <el-form :inline="true" :model="queryForm" ref="activationFilter">
      <el-form-item label="激活码" prop="activationCode">
        <el-input placeholder="输入激活码检索" v-model="queryForm.activationCode" :maxlength="32"/>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input placeholder="输入名称检索" v-model="queryForm.name"></el-input>
      </el-form-item>
      <el-form-item label="使用状态" prop="status">
        <el-select v-model="queryForm.status" :multiple="true">
          <el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
       <el-form-item label="邮件状态" prop="eStatus">
        <el-select v-model="queryForm.eStatus">
          <el-option
            v-for="data in emailOption"
            :key="data.value"
            :label="data.label"
            :value="data.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户名称" prop="username">
        <el-input placeholder="输入用户名称检索" v-model="queryForm.username"></el-input>
      </el-form-item>
       <el-form-item label="用户邮箱" prop="email">
        <el-input placeholder="输入用户邮箱检索" v-model="queryForm.email"></el-input>
      </el-form-item>
      <el-form-item label="激活时间" prop="time">
        <el-date-picker
          :picker-options="datePick"
          v-model="queryForm.time"
          style="width:168px"
          format="yyyy.MM.dd"
          type="daterange"
          range-separator="-"
          :maxTime="today"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="请选择"
          end-placeholder="请选择"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <div class="text-btn">
      <span class="serach-button" @click="send">查询</span>
      <span class="clear-button" @click="clearSearch">清空</span>
    </div>
  </div>
</template>

<script>
import bus from "./bus";
import { status, expiration, emailOption } from "./var";
export default {
  data() {
    var disableEndDate = (time) => {
      let _now = Date.now();
      //大于当前的禁止
      return time.getTime() > _now;
    };
    return {
      queryForm: {
        activationCode: "",
        name: "",
        username: "",
        email: "",
        status: "",
        eStatus: "",
        expiration: "",
        time: [],
      },
      status: status,
      emailOption,
      expiration: expiration,
      today: new Date(),
      datePick: {
        disabledDate(time) {
          return disableEndDate(time);
        },
      },
    };
  },
  methods: {
    clearSearch() {
      this.clearForm("activationFilter");
    },
    send() {
      const form = {
        name: this.queryForm.name,
        code: this.queryForm.activationCode,
        status: this.queryForm.status,
        email_status: this.queryForm.eStatus,
        type: this.queryForm.expiration,
        username: this.queryForm.username,
        activate_customer_email: this.queryForm.email,
        activated_at: {
          from:
            this.queryForm.time && this.queryForm.time.length > 1
              ? this.queryForm.time[0]
              : "",
          to:
            this.queryForm.time && this.queryForm.time.length > 1
              ? this.queryForm.time[1].split(" ")[0] + " 23:59:59"
              : "",
        },
      };
      bus.$emit("makeFilter", form);
    },
  },
};
</script>
