<template>
    <el-dialog
      top="5%"
      :close-on-click-modal="false"
      title="发送邮件"
      :visible.sync="dialogVisible"
      width="444px"
      :modal-append-to-body="false"
      class="pop-dialog aops-sendEmail-dialog"
    >
     <el-form
        ref="refForm"
        :model="currentForm"
        label-width="100px"
        label-position="left"
        :rules="rule"
      >
        <el-form-item label="编号" >
          <el-input
            v-model="currentForm.id"
            style="width: 240px;"
            class="dialog-input"
            disabled
          />
        </el-form-item>
        <el-form-item v-if="type === 'inception'" label="激活码名称">
          <el-input
            v-model="currentForm.name"
            style="width: 240px;"
            disabled
            class="dialog-input"
          />
        </el-form-item>
        <el-form-item label="用户邮箱" prop="email">
          <el-input
            v-model="currentForm.email"
            style="width: 240px;"
            class="dialog-input"
            placeholder="输入用户邮箱"
            @blur="handleSearchCustomer"
          />
        </el-form-item>
         <el-form-item label="用户名称" prop="username">
          <el-input
            v-model="currentForm.username"
            style="width: 240px;"
            class="dialog-input"
            placeholder=""
            disabled
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
            type="primary"
            class="confirm-button"
            size="medium"
            :loading="btnLoading"
            :disabled="!currentForm.username"
            @click="sure">确认</el-button>
        <el-button
           class="cancel-button"
           @click="hide">取消</el-button>
      </div>
    </el-dialog>
</template>
<script>
import keyApi from '@/api/inception-key.js';
import subscriptionApi from '@/api/subscription';

export default {
    props: {
        type: {
            type: String,
            default: 'inception' // 激活码
        }
    },
    /**
    * 基础数据&定义数据
     @return{*}
    */
    data() {
        return {
            dialogVisible: false,
            btnLoading: false,
            currentForm: {
                id: '',
                name: '',
                email: '',
                username: '',
                userId: ''
            },
            rule: {
                email: [
                    { required: true, message: '请输入用户邮箱', trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        /**
         * 点击确认按钮
        */
        async sure() {
            const data = {
                id: this.currentForm.id,
                customer_id: this.currentForm.userId
            };
            try {
                this.btnLoading = true;
                const resp = this.type === 'inception'
                    ? await keyApi.sendEmail(data)
                    : await subscriptionApi.sendEmail(data);
                if (resp.data.code === 10200) {
                    this.$$success(`${this.type === 'inception' ? '激活码' : '订阅码'}邮件已发送`);
                    this.updateList();
                    this.hide();
                } else {
                    this.$$error(resp.data.message);
                }
                this.btnLoading = false;
            } catch (error) {
                this.btnLoading = false;
            }
        },
        /** 显示弹窗
         * @param {*} data 值
        */
        show(data) {
            this.dialogVisible = true;
            this.currentForm.id = data.id;
            this.currentForm.name = data.name;
        },
        // /**
        //    给表单数据初始化
        //  * @param {*} data 值
        //  */
        // setCurrentForm(data) {
        //     this.currentForm = {
        //         id: data.id,
        //         name: data.name,
        //         email: '',
        //         username: '',
        //         userId: ''
        //     };
        // },
        /**
         * 查询用户信息失败，清空用户信息
        */
        clearUserInfo() {
            this.currentForm.username = '';
            this.currentForm.userId = '';
        },
        /**
          根据输入的邮箱，查询用户信息
         */
        async handleSearchCustomer() {
            if (!this.currentForm.email) { return; }
            const res = this.type === 'inception'
                ? await keyApi.customerInfo({ email: this.currentForm.email })
                : await subscriptionApi.customerInfo({ email: this.currentForm.email });
            if (res.data.code === 10200) {
                const resData = res.data.data || {};
                if (`${resData.activation_code_used}` === '2') {
                    this.$$error(`${resData.username} 已接收或使用过激活码，不可重复获取`);
                    this.clearUserInfo();
                } else {
                    this.currentForm.username = resData.username;
                    this.currentForm.userId = resData.id;
                }
            } else if (res.data.code === 10001) {
                this.$$error(res.data.message);
                this.clearUserInfo();
            }
        },
        /** 关闭弹窗  */
        hide() {
            this.dialogVisible = false;
            this.clearForm('refForm');
        },
        /**
        数据更新发送消息
         */
        updateList() {
            this.$emit('updateList');
        }
    }
};
</script>
