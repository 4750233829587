<template>
  <div>
      <el-table
        :data="listData"
        style="width: 100%"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
        v-loading="tableLoading"
        class="data-table"
        ref="promoTable"
      >
        <el-table-column label="编号" prop="id" width="120px" show-overflow-tooltip></el-table-column>
        <el-table-column label="激活码" prop="code" width="180px" show-overflow-tooltip></el-table-column>
        <el-table-column label="名称" prop="name" width="150px" show-overflow-tooltip></el-table-column>
        <el-table-column label="用户名称" width="150px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{renderInfo(scope.row, 'username')}}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户邮箱" width="250px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{renderInfo(scope.row, 'email')}}</span>
          </template>
        </el-table-column>
        <el-table-column label="激活时间" width="160px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{scope.row.activated_at || '--'}}</span>
          </template>
        </el-table-column>
         <el-table-column label="有效截止时间" width="160px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{setExpired(scope.row)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="使用状态" width="100px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{setStatus(scope.row)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="邮件状态" width="100px" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{setEmailStatus(scope.row)}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" min-width="330px" align="left">
          <!-- 条目操作 -->
          <template slot-scope="scope">
            <div class="icon-btn">
              <el-button
                size="mini"
                type="text"
                v-clipboard:copy="scope.row.code"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <i class="iconfont">&#xe69a;</i> 复制激活码
              </el-button>
              <el-button
                class="preview-color"
                size="mini"
                type="text"
                v-if="setStatus(scope.row) === '未使用'"
                @click="handleItem(scope.row)"
              >
                <i class="iconfont">&#xe661;</i> 发送邮件
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    <div class="ta-r mg-t-18">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>

</template>

<script>
import bus from "./bus";
import api from "@/api/inception-key";
import { emailOption } from "./var";

export default {
  data() {
    return {
      listData: [],
      total: 0,
      tableLoading: false,
      currentPage: 1,
      pageSize: 10,
      filter: {},
      status: {
          0:'未使用',
          1:'已使用',
          2:'已失效'
      }
    };
  },
  computed: {
    queryInfo() {
      const query = {
        limit: this.pageSize,
        page: this.currentPage,
        filter: this.filter,
        order_by: "id",
        sort: "desc",
      };
      return query;
    }
  },
  methods: {
    init() {
      this.getActivation();
    },
    getActivation() {
      this.tableLoading = true;
      api.activationList(this.queryInfo).then((res) => {
        if (res.data.code === 10200) {
          this.listData = res.data.data.items;
          this.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.init();
    },
    onCopy(e) {
      this.$$success("激活码复制成功");
    },
    onError(e) {
      this.$$error("激活码复制失败");
    },
    renderInfo(row,type) {
        let data = '--';
        if(row.activate_customer) {
          data = row.activate_customer[type]
        }
        return data;
    },
    setExpired (row) {
        const expired = row.expired ? this.$moment(new Date(row.expired*1000)).format('YYYY-MM-DD') :'' ;
        return expired
    },
    setStatus(row) {
        return  this.status[row.status];

    },
    setEmailStatus(row) {
        const child = emailOption.find(item => item.value === row.email_status)
        return (child && child.label) || '--';
    },
    /**
        发送邮件
     */
    handleItem(row) {
        if (row.activate_customer_id) {
            this.sendEmail(row)
        } else {
            this.$emit("handleItem", row);
        }
    },
    async sendEmail(row) {
        const resp = await api.sendEmail({
            id: row.id,
            customer_id: row.activate_customer_id
        });
        if (resp.data.code === 10200) {
            this.$$success('激活码邮件已重新发送');
            this.init();
        } else {
            this.$$error(resp.data.message);
        }
    }
  },
  mounted() {
    this.init();
  },
  created() {
    bus.$on("makeFilter", (val) => {
      this.filter = val;
      this.currentPage = 1;
      this.init();
    });
  },
};
</script>
